<template>
    <div>
        <div class="mt-3 vx-row">
            <header class="w-full mb-4 vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <h4>{{ $t("apps.registered-users.exportcsv") }}</h4>
                <p>{{ $t("apps.registered-users.exportdescr") }}</p>
            </header>
        </div>
        <div>
            <ExportForm :buttonText="$t('apps.registered-users.exportusers')" @exportData="exportData" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExportForm from '@/modules/Shared/Components/form/ExportForm'
import RegisteredUsersService from '@/modules/Apps/Services/RegisteredUsersService'

export default {
    name: 'registered-users-export-form',
    components: {
        ExportForm
    },
    computed: {
        ...mapGetters( ['applicationUuid'] )
    },
    methods: {
        async exportData(params) {
            await RegisteredUsersService.exportRegisteredUsers(this.applicationUuid, params)

            this.$vs.notify( { text: this.$t('apps.registered-users.exportsent', { email: params.email }), color: 'success',position:'top-right' } )

            this.$router.push({ name: 'registeredusers', params: { uuid: this.applicationUuid } });
        }
    }
}
</script>