import axios from '@/axios.js'

export default {
    getRegisteredUsers: async (appUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/users`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    exportRegisteredUsers: async (appUuid, parameters) => {
        try {
            const params = { ...parameters, type: 'csv' }
            const response = await axios.editorapi.get(`apps/v3/export/${appUuid}/users`, { params: params })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
}